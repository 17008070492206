
import { defineComponent, ref, inject, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Form from "../components/SiteProductForm.vue"

import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    Form
  },
  setup() {
    const store = useStore();
    const router = useRoute();
    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            slots: { customRender: "product" },
        },
        {
            title: 'Tank',
            dataIndex: 'tank',
            slots: { customRender: "tank" },
        },
        {
            title: 'Pump',
            dataIndex: 'pump',
            slots: { customRender: "pump" },
        },
        {
            title: "Action",
            dataIndex: "action",
            slots: { customRender: "action" },
            align: 'center'
        }
    ];
    const site_product_form = ref<InstanceType<typeof Form>>();
    const site = inject<any>('site');

    const create = () => {
      site_product_form.value?.create();
    };

    const edit = (record: any) => {
      site_product_form.value?.edit(record);
    };

    const remove = (row:any) => {
      site.site_products.map(function (value:any, key:any) {
        if (value.id == row.id) {
            site.site_products.splice(key, 1);
        }
      });
    };

    const deleteRecord = (row:any) => {
      store.dispatch("SiteProduct/destroy", row.id).then((response) => (
        site.site_products.map(function (value:any, key:any) {
          if (value.id == response.data) {
              site.site_products.splice(key, 1);
          }
        })
      ));
      
    };
    const getAllProducts = () => {
        store.dispatch("Option/getAllProducts");
    };
    const getAllTanks = () => {
        store.dispatch("Option/getAllTanks");
    };
    const getAllPumps = () => {
        store.dispatch("Option/getAllPumps");
    };

    /* COMPUTED */
 
    const isNew = computed(() =>
      router.name == "site-details-create" ? true : false
    );
    const all_products = computed(() => store.getters["Option/all_products"]);
    const all_tanks = computed(() => store.getters["Option/all_tanks"]);
    const all_pumps = computed(() => store.getters["Option/all_pumps"]);

    /* MOUNTED */
  

    return {
        columns,
        site,
        site_product_form,
        all_products,
        all_tanks,
        all_pumps,
        isNew,
        create,
        edit,
        remove,
        deleteRecord
    };
  },
});
