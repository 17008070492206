
import { defineComponent, ref, reactive, computed, onMounted, UnwrapRef, provide, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SubCluster from './components/SubCluster.vue'
import SiteProduct from './components/SiteProduct.vue'
import axios from '../../../../axios'

interface FormState {
  retailer_id: Array<any>;
  arnoc: string;
  ship_to: string;
  site_name: string;
  nhp_dealer: any;
  nti: any;
  territory_manager_id: string;
  cluster_id: string;
  zone_id: string;
  id: string;
  site_products: any;
  remove_site_products: any;
  site_sub_clusters: any;
  remove_site_sub_clusters: any;
  type: string,
  retailer_name: string;
  retailer_owner_id: string
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
  },
  components: {
    SubCluster,
    SiteProduct
  },
  setup(props) {
    /* DATA */
    const router = useRouter()
    const checked_nhp = ref(false)
    const checked_nti = ref(false)
    const store = useStore()
    const full_district = ref('')
    const district_manager = ref('')
    const isLoading = ref<boolean>(false)
    const territory_manager = ref('')

    const form: UnwrapRef<FormState> = reactive({
      id: '',
      retailer_id: [],
      arnoc: '',
      ship_to: '',
      site_name: '',
      nhp_dealer: '0',
      nti: '0',
      territory_manager_id: '',
      cluster_id: '',
      zone_id: '',
      type: '',
      retailer_name: '',
      retailer_owner_id: '',
      site_products: [
        // {
        //     product_id: "",
        //     plant_id:"",
        //     site_id:"",
        //     tank_id:"",
        //     pump_id:""
        // },
      ],
      remove_site_products: [],
      site_sub_clusters: [],
      remove_site_sub_clusters: [],
    })

    const user = reactive({
      id: '',
      role: ''
    })

    provide('site', form)

    /* METHODS */
    function addRowProducts() {
      form.site_products.push({
        product_id: '',
        plant_id: '',
        site_id: '',
        tank_id: '',
        pump_id: '',
        id: ''
      })
    }

    function addRowSubCluster() {
      form.site_sub_clusters.push({
        site_id: '',
        sub_cluster_id: '',
        id: ''
      })
    }

    function deleteRowProducts(row: any, id: any) {
      form.remove_site_products.push({
        id: id
      })
      form.site_products.splice(row, 1)
    }

    function deleteRowSubCluster(row: any, id: any) {
      form.remove_site_sub_clusters.push({
        id: id
      })
      form.site_sub_clusters.splice(row, 1)
    }

    const handleFinish = async (values: FormState) => {
      if (props.id) {
        form.id = props.id
        store.dispatch('SiteDetails/update', form)
      } else {
        store.dispatch('SiteDetails/store', form)
      }

    }
    const onChangeNhpDealer = (e: any) => {
      checked_nhp.value = e.target.checked
      form.nhp_dealer = checked_nhp.value == false ? '0' : '1'
    }
    const onChangeNti = (e: any) => {
      checked_nti.value = e.target.checked
      form.nti = checked_nti.value == false ? '0' : '1'
    }
    const getZone = (id: any) => {
      store.dispatch('Zone/show', id).then((res) => (
        territory_manager.value = res.data.territory_manager.full_name,
        full_district.value = res.data.district.district_name,
        district_manager.value = res.data.district.district_manager.full_name
      ))
    }

    const handleBack = () => {
      router.push('/admin-site-details')
    }

    const getSite = () => {
      if (props.id) {
        isLoading.value = true
        store
          .dispatch('SiteDetails/show', props.id)
          .then(
            (res) => (
              (form.id = res.data.id),
              (res.data.retailers.forEach(element => {
                form.retailer_id.push(element.retailer_user_id)
              })),
              (form.arnoc = res.data.arnoc),
              (form.ship_to = res.data.ship_to),
              (form.site_name = res.data.site_name),
              (form.zone_id = res.data.zone_id),
              (form.territory_manager_id = res.data.territory_manager_id),
              (form.cluster_id = res.data.cluster_id),
              (form.site_products = res.data.site_products),
              (form.site_sub_clusters = res.data.site_sub_clusters),
              (form.type = res.data.type),
              (form.nhp_dealer = res.data.nhp_dealer),
              (form.nti = res.data.nti),
              (form.retailer_owner_id = res.data.retailer_owner_id),
              isLoading.value = false,
              checked_nhp.value = res.data.nhp_dealer == 1 ? true : false,
              checked_nti.value = res.data.nti == 1 ? true : false,
              full_district.value = res.data.zone.district.district_name,
              district_manager.value = res.data.zone.district.district_manager.full_name,
              territory_manager.value = res.data.zone.territory_manager.full_name,
              form.retailer_name = res.data.retailer.full_name
            )
          )
      }
    }

    const getUser = async () => {
      const url = 'auth/user'
      const response = await axios.get(url)
      const user = response.data.data

      const { role } = toRefs(user)
      role.value = user.role.name
      getAllRetailers()
    }

    const getAllPlants = () => {
      store.dispatch('Option/getAllPlants')
    }

    const getAllRetailers = () => {
      store.dispatch('Option/getAllRetailers')
    }

    const getAllZones = () => {
      store.dispatch('Option/getAllZones')
    }
    const getRTM = () => {
      store.dispatch('Option/getAllRetailTerritoryManagers')
    }
    const getAllClusters = () => {
      store.dispatch('Option/getAllClusters')
    }
    const getAllRetailerOwners = () => {
      store.dispatch('Option/getAllRetailerOwners')
    }

    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    /* COMPUTED */
    const all_retailers = computed(() => store.getters['Option/all_retailers'])
    const all_zones = computed(() => store.getters['Option/all_zones'])
    const retail_territory_managers = computed(() => store.getters['Option/all_retail_territory_managers'])
    const all_clusters = computed(() => store.getters['Option/all_clusters'])
    const all_plants = computed(() => store.getters['Option/all_plants'])
    const auth_user = computed(() => store.state.AuthUser.user)
    const all_retailer_owners = computed(() => store.getters['Option/all_retailer_owners'])

    /* MOUNTED */
    onMounted(() => {
      getSite()
      getAllZones()
      getRTM()
      getAllClusters()
      getUser()
      getAllRetailerOwners()
      scrollToTop()
    })

    return {
      form,
      all_retailers,
      all_zones,
      all_clusters,
      all_plants,
      all_retailer_owners,
      checked_nti,
      checked_nhp,
      retail_territory_managers,
      full_district,
      district_manager,
      territory_manager,
      auth_user,
      user,
      handleFinish,
      onChangeNhpDealer,
      onChangeNti,
      getZone,
      getRTM,
      addRowProducts,
      deleteRowProducts,
      deleteRowSubCluster,
      addRowSubCluster,
      handleBack,
      isLoading,
      filterOption
    }
  }
})
