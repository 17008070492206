
import { defineComponent, ref, computed, inject, reactive, toRefs, onMounted, UnwrapRef } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'

interface Pump {
  value: string;
  label: string;
}

interface FormState {
  id: string;
  product_id: string;
  tank_id: string;
  site_id: string;
  pumps: Pump[]
}

const initialState = {
  id: '',
  product_id: '',
  tank_id: '',
  pumps: [],
  site_id: '',
}

export default defineComponent({

  setup () {
    /* DATA */
    const store = useStore()
    const router = useRoute()
    const form: UnwrapRef<FormState> = reactive({
      id: '',
      product_id: '',
      tank_id: '',
      site_id: '',
      pumps: []
    })

    const site = inject<any>('site')
    const visible = ref(false)
    const form_action = ref('save')
    const id = ref(0)
    const selectedItems = ref([])

    /* METHODS */
    const create = () => {
      Object.assign(form, initialState)
      visible.value = true
      form_action.value = 'save'
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const edit = (record: any) => {
      var list: any[] = []
      if (record.site_product_pump) {
        record.site_product_pump.forEach((item: any) => {
          list.push(item.pump_id)
        })
      }

      const {id, product_id, site_id, tank_id, pumps} = toRefs(form)
      id.value = record.id
      product_id.value = record.product_id
      site_id.value = record.site_id
      tank_id.value = record.tank_id

      pumps.value = !record.pumps ? list : record.pumps
      visible.value = true
      form_action.value = 'update'
    }

    const save = () => {
      if (!form.product_id || !form.tank_id || form.pumps.length === 0) {
        Modal.error({
          title: 'Error',
          content: 'Please make sure you have a product, tank and pump selected.',
        })
        return
      }

      form_action.value == 'save' ? addProduct(form) : updateProduct(form)
    }

    const addProduct = (payload: any) => {
      if (isNew.value) {
        site.site_products.push({
          id: id.value++,
          product_id: form.product_id,
          tank_id: form.tank_id,
          site_id: form.site_id,
          pumps: form.pumps
        })
        visible.value = false
      } else {
        payload.site_id = site.id
        store
        .dispatch('SiteProduct/store', payload)
        .then((response) => (
          visible.value = false,
            site.site_products.push({
              id: response.data.id,
              product_id: response.data.product_id,
              tank_id: response.data.tank_id,
              pumps: form.pumps,
              site_id: response.data.site_id,
            })
        ))
      }
    }

    const updateProduct = (payload: any) => {
      let id = form.id

      if (isNew.value) {
        site.site_products.find(function (value: any, key: any) {

          if (value.id == id) {
            console.log(form)
            site.site_products[key] = Object.assign({}, form)
          }
        })

        visible.value = false
      } else {
        store
          .dispatch('SiteProduct/update', payload)
          .then((response) => (
            visible.value = false,
              site.site_products.find(function (value: any, key: any) {
                if (value.id == response.data.id) {
                  site.site_products[key] = Object.assign({}, response.data)
                }
              })
          ))
      }
    }

    const cancel = () => {
      visible.value = false
    }

    const getAllProducts = () => {
      store.dispatch('Option/getAllProducts')
    }
    const getAllTanks = () => {
      store.dispatch('Option/getAllTanks')
    }
    const getAllPumps = () => {
      store.dispatch('Option/getAllPumps')
    }

    /* COMPUTED */

    const isNew = computed(() =>
        router.name == 'site-details-create'
    )
    const all_products = computed(() => store.getters['Option/all_products'])
    const all_tanks = computed(() => store.getters['Option/all_tanks'])
    const all_pumps = computed(() => store.getters['Option/all_pumps'])

    /* MOUNTED */
    onMounted(getAllProducts)
    onMounted(getAllTanks)
    onMounted(getAllPumps)

    return {
      all_products,
      all_tanks,
      all_pumps,
      site,
      form,
      visible,
      form_action,
      isNew,
      save,
      create,
      edit,
      cancel,
      selectedItems,
      filterOption
    }
  },
})
